@charset "utf-8";

/* //////////////////////////////////////////////////
[Module]
////////////////////////////////////////////////// */

/* --------------------------------------------------
  Clearfix
-------------------------------------------------- */
.group:after {
  display: block;
  content: "";
  clear: both;
}


/* --------------------------------------------------
  Interaction
-------------------------------------------------- */
a img,
.hover a {
  -webkit-transition: 0.1s linear;
  transition: 0.1s linear;
  background: rgba(255,255,255,0.01);
}

a:hover img,
.hover a:hover,
.hover a:hover img {
  -ms-filter: "alpha(opacity=70)";
  opacity: 0.70;
}



/* --------------------------------------------------
  Heading
-------------------------------------------------- */
.title-a {
  line-height: 1;
  margin-bottom: 4rem;
  text-align: center;

  .en {
    display: inline-block;
    line-height: (42 / 32);
    font-weight: 400;
    font-size: 3.2rem;
    @extend %font-en;
    @include ls-center(100);

    &:after {
      content: '';
      display: block;
      width: calc(100% - 0.2em);
      height: .2rem;
      margin: 1rem auto 0;
      background-color: #000;
    }
  }

  .jp {
   display: block;
   line-height: (20 / 13);
   margin-top: 1.6rem;
   font-size: 1.3rem;
  }
}

.title-b {
  line-height: 1;
  margin-bottom: 4rem;
  text-align: center;

  .txt {
    display: inline-block;
    line-height: (42 / 32);
    font-weight: 400;
    font-size: 3.2rem;
    @include ls-center(100);

    &:after {
      content: '';
      display: block;
      width: calc(100% - 0.2em);
      height: .2rem;
      margin: 1rem auto 0;
      background-color: #000;
    }
  }
}

/* --------------------------------------------------
  Button
-------------------------------------------------- */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  text-decoration: none !important;
  text-align: center;
  appearance: none;
  cursor: pointer;
  @include transition-base;

  &-a {
    border: 1px solid #000;
    color: #fff;
    background-color: #000;

    &:link,
    &:visited {
      color: #fff;
    }

    &:hover {
      color: #000;
      background-color: #fff;
    }
  }

  &-b {
    color: #000;
    background-color: #EDEDED;

    &:link,
    &:visited {
      color: #000;
    }

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }

  &-ghost {
    border: 1px solid #fff;
    color: #fff;
    background-color: transparent;

    &:link,
    &:visited {
      color: #fff;
    }

    &:hover {
      color: #000;
      background-color: #fff;
    }
  }
}

/* --------------------------------------------------
  Text
-------------------------------------------------- */
%font-en {
  font-family: $font-family-en;
}


/* --------------------------------------------------
  Image Width Fixed
-------------------------------------------------- */


/* --------------------------------------------------
  Bread Crumbs
-------------------------------------------------- */
#M_topicPathWrap {
  max-width: 1440px;
  line-height: (20 / 12);
  margin: 3rem auto 7rem;
  padding-right: 2rem;
  padding-left: 2rem;
  font-size: 1.2rem;
}

#M_topicPathWrap li {
  margin-right: 0;
  padding-right: 0;
  background: none;

  &:after {
    content: '>';
    display: inline-block;
    margin: 0 (6 / 12)+em;
    color: #666;
    font-family: 'Noto Sans JP', sans-serif;
  }
}

#M_topicPathWrap li.M_current {

  &:after {
    content: none;
  }

  a {
    text-decoration: none;
  }
}

/* --------------------------------------------------
  Local Nav
-------------------------------------------------- */


/* --------------------------------------------------
  List
-------------------------------------------------- */


/* --------------------------------------------------
  Link
-------------------------------------------------- */


/* --------------------------------------------------
  Section
-------------------------------------------------- */


/* --------------------------------------------------
  Box
-------------------------------------------------- */


/* --------------------------------------------------
  Table
-------------------------------------------------- */


/* --------------------------------------------------
  Form
-------------------------------------------------- */



/* --------------------------------------------------
  Responsive Grid
-------------------------------------------------- */



/* --------------------------------------------------
  Block Model
-------------------------------------------------- */


/* --------------------------------------------------
  Item List
-------------------------------------------------- */
//デフォルトを上書き
.category-list-inner{
  padding: 0;
}

.item-list img {
  margin-bottom: 0;
  border-radius: 0;
}

.item-list a {
  font-size: 100%;
}

/* 汎用商品リスト */
.item-list {
  display: flex;
  flex-wrap: wrap;
  line-height: (20 / 14);
  margin: 0 -1rem;
  font-size: 1.4rem;

  > li {
    flex-basis: percentage(284 / 1420);
    max-width: percentage(284 / 1420);
    //width: percentage(284 / 1420);
    margin-bottom: 5.6rem;
    padding: 0 1rem;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  &-img {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1.6rem;

    &:before {
      display: block;
      content: "";
      padding-top: 100%;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .name {
    margin-bottom: 1.8rem;

    > a {

      &:hover {
        opacity: .7;
      }
    }
  }

  .price {
    line-height: (22 / 16);
    margin-top: .7rem;
    font-weight: normal;
    font-size: 1.6rem;

    span {
      font-size: 1.4rem;
    }
  }
}

// カルーセルにする
// .item-list.js-item-carousel {
//   display: block;
//   margin: 0;

//   > li {
//     max-width: none;
//     padding: 0;
//   }
// }

// .js-item-carousel{
//   opacity: 0;
// }

/* ランキングリスト */
.rank-list {

  > li {
    position: relative;
  }
}

.rank-number {
  top: 10px;
  left: 20px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-weight: 400;
  font-size: 1.3rem;
}



/* --------------------------------------------------
  Category Page Module
-------------------------------------------------- */
/* サブカテゴリーリスト */
.category-sublist {
  margin: 10rem -1rem 8rem;

  table {
    width: auto;

    // #makebanner td[width="640"] & {
    //   width: auto;
    // }

    .line {
      height: 0;
    }

    td {
      width: auto;
    }

    img {
      display: none;
    }

    a {
      display: inline-flex;
      align-items: center;
      position: relative;
      // width: 26.4rem;
      width: 100%;
      max-width: 26.4rem;
      height: 5rem;
      // margin: 0 1rem 2rem;
      padding: 0 2rem;
      border-radius: .4rem;
      background-color: #EFEFEF;
      font-size: 1.4rem;
      text-decoration: none;
      @include transition-base;

      &:after {
        content: '\f105';
        position: absolute;
        top: 0;
        bottom: 0;
        right: .6rem;
        width: 1em;
        height: (20 / 14)+em;
        margin: auto 0;
        font-family: 'Line Awesome Free';
        font-weight: 900;
      }

      &:hover {
        color: #fff;
        background-color: #000;
      }
    }

    & > tbody tr:nth-child( n + 2 ) > td{
        width: 20%;
        padding: 0 1rem 2rem;
    }
  }

  #makebanner td[width="640"] & > table {
      width: 100%;
  }

}

/* カテゴリーページ UI */
.category-sort {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;

  .list-sort-select {
    margin: 0 auto 0 0;
  }

  .list-sort {
    margin: 0;
  }

  .list-total {
    margin-left: auto;
  }

  .list-pager {
    margin-left: 2rem;
  }
}

/* トータル */
.list-total {
  line-height: (22 / 16);
  font-size: 1.6rem;
}

/* ソート */
.list-sort-select {
  position: relative;
  width: 20rem;
  height: 4rem;

  &:after {
    content: '\f107';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 12;
    right: 1.4rem;
    margin: auto 0;
    width: 1em;
    height: (21 / 14)+em;
    font-family: 'Line Awesome Free';
    font-weight: 900;
    @include transition-base;
  }

  .list-sort-display {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 11;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    padding: 0 3rem 0 1rem;
    border-radius: .4rem;
    background-color: #fff;
    //background-color: transparent;
    @include transition-base;
  }

  .list-sort {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    li {
      display: block;
      margin: 0;
      @include transition-base;

      &:nth-child(n+2) {
        height: 0;
      }
    }
  }

  &.is-open {

    &:after {
      transform: rotate(-180deg);
    }

    .list-sort-display {
      opacity: 0;
      visibility: hidden;
    }

    .list-sort {

      li {
        //height: 3.8rem;

        &:nth-child(n+2) {
          height: 3.8rem;
        }
      }
    }
  }
}

.list-sort {
  border: 1px solid #707070;
  border-radius: .4rem;
  background-color: #fff;
  text-align: left;
  overflow: hidden;

  a {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 3.8rem;
    padding: 0 3rem 0 1rem;
    text-decoration: none;

    &:hover {
      background-color: #efefef;
    }
  }
}

/* ページャー */
.list-pager {

  .M_pager {
    display: flex;
    margin: 0;

    li {
      display: inline-flex;
      line-height: (24 / 14);
      margin-right: .5rem;
      font-size: 1.4rem;

      &:last-child {
        margin-right: 0;
      }

      &.prev,
      &.next {
        position: relative;
        width: 5rem;
        height: 4rem;
        color: #999;
        background: #EFEFEF;
        text-indent: -9999px;

        a {
          width: 5rem;
          height: 4rem;
          color: #fff;
          background: #999999;

          &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 1em;
            height: (22 / 14)+em;
            margin: auto;
            font-family: 'Line Awesome Free';
            font-weight: 900;
            text-indent: 0;
          }

          &:hover {
            color: #999;
            background: #EFEFEF;
          }
        }
      }

      &.prev,
      &.next:first-child {

        a {

          &:after {
            content: '\f104';
          }
        }
      }

      &.next {

        a {

          &:after {
            content: '\f105';
          }
        }
      }

      &.active {
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        padding: 2px;
        border: 1px solid #000;
        color: #000;
        background: #fff;
        font-weight: 400;
      }

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        padding: 2px;
        border: 1px solid #000;
        color: #fff;
        background: #000;
        @include transition-base;

        &:visited {
          color: #fff;
        }

        &:hover {
          color: #000;
          background-color: #fff;
        }
      }
    }
  }
}

/* カテゴリーリスト */
.category-list {

  &-img {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.6rem;

    &:before {
      display: block;
      content: "";
      padding-top: 100%;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}



/* --------------------------------------------------
  News List
-------------------------------------------------- */
.news-list {
  max-width: 832px;
  margin: 0 auto 5rem;
  border-top: 1px solid #EEE;

  &-item {
    display: flex;
    padding: 2.7rem 0;
    border-bottom: 1px solid #EEE;
    font-size: 1.4rem;
  }

  &-date {
    flex-basis: 11rem;
    max-width: 11rem;
    line-height: (24 / 14);
    padding-right: 1em;
    color: #666;
    letter-spacing: (50 / 1000)+em;
  }

  &-title {
    flex-grow: 1;
    line-height: (24 / 14);

    > a {
      text-decoration: none;
      @include transition-base;

      &:hover {
        opacity: .7;
      }
    }
  }
}

/* サーチ */
.item-search{
  border: none;
  background: #EFEFEF;
  border-radius: 0;
  padding:  3rem;
  margin-bottom: 4rem;

  table{
    margin-bottom: 2rem;
    th{
      vertical-align: middle;
      width: 10%;
    }

    td{
      select + select{
        margin-left: 1%;
      }
    }

    input{
      width: calc( 80% + 1.5rem );
    }


    #searchPrice{
      input{
        width: 40%;
      }
    }
  }

  .btn-search{
    display: block;
    width: 22rem;
    border-radius: 0;
    text-align: center;
    position: relative;
    margin: 0 auto;
    text-decoration: none;
    padding: 1rem;
    box-sizing: border-box;
    border: solid 1px #000;

    &:before{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      font-weight: 900;
      font-size: 1.8rem;
      line-height: 1em;
      font-family: 'Line Awesome Free';
      text-indent: 0;
      content: '\f002';
      transform: translate(2.8rem,-0.1rem);
    }

    &:hover{
      opacity: 1;
      background: #FFF;
      color: #000 !important;

      &:before{
        color: #000;
      }
    }
  }
}

/* --------------------------------------------------
  SVG
-------------------------------------------------- */
.svg-symbols {
  display: none;
}

.svg-use {
  display: inline-block;
  overflow: hidden;
}



/* --------------------------------------------------
  Show / Hide / Line Break
-------------------------------------------------- */
.br-tab,
.br-sp {
  display: none;
}

.br-tab.br-pc,
.br-sp.br-pc,
.br-pc {
  display: inline-block;
}

.hidden-block-pc {
  display: none;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

@include mediaquery-tablet {

.br-sp.br-pc,
.br-pc,
.br-sp {
  display: none;
}

.br-pc.br-tab,
.br-sp.br-tab,
.br-tab {
  display: inline-block;
}

.hidden-block-pc.hidden-block-tab,
.hidden-block-sp.hidden-block-tab,
.hidden-block-tab {
  display: none;
}

.hidden-block-sp.hidden-block-pc,
.hidden-block-pc,
.hidden-block-sp {
  display: block;
}

}// lt TAB

@include mediaquery-sp {

.br-tab.br-pc,
.br-pc,
.br-tab {
  display: none;
}

.br-pc.br-sp,
.br-tab.br-sp,
.br-sp {
  display: inline-block;
}

.hidden-block-pc.hidden-block-sp,
.hidden-block-tab.hidden-block-sp,
.hidden-block-sp {
	display: none;
}

.hidden-block-tab.hidden-block-pc,
.hidden-block-pc,
.hidden-block-tab {
	display: block;
}

}// SP


/* --------------------------------------------------
  Other
-------------------------------------------------- */
/* bxSlider */
.bx-wrapper {
  border: none;
  box-shadow: none;

  .bx-controls-direction {
    a {
      width: 50px;
      height: 50px;
      margin-top: -25px;
    }
  }

  .bx-prev {
    left: 0;
    background: url(../img/common/ico_arrow_prev_01.png) no-repeat 0 0;

    &:hover,
    &:focus {
      background-position: 0 0;
    }
  }

  .bx-next {
    right: 0;
    background: url(../img/common/ico_arrow_next_01.png) no-repeat 0 0;

    &:hover,
    &:focus {
      background-position: 0 0;
    }
  }
}

/* Object Fit Images */
.ofi-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;'
}
