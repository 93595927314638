@charset "utf-8";

/* --------------------------------------------------
  1. Init
-------------------------------------------------- */
img {
  border: none;
  vertical-align: bottom;
}

picture {
  display: block;
}

object {
  vertical-align: middle;
  outline: none;
}

em,
strong {
  font-weight: bold;
  font-style: normal;
}

small {
  font-size: 100%;
}

abbr,
acronym {
  border: none;
  font-variant: normal;
}

q:before,
q:after {
  content: '';
}

address,
caption,
cite,
code,
dfn,
var {
  font-weight: normal;
  font-style: normal;
}

code,
pre {
  font-family: "Poppins", "Noto Sans JP", sans-serif, monospace;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  word-break: normal;
}
