@charset "utf-8";

/* //////////////////////////////////////////////////
[Pages]
////////////////////////////////////////////////// */

/* --------------------------------------------------
HOME
-------------------------------------------------- */
/* Section */
.section-pickup,
.section-ranking,
.section-news {
  margin-bottom: 9rem;
}

/* Main Visual */
.main-visual {
  position: relative;
  z-index: 0;
  margin-bottom: 7rem;

  .bx-viewport {
    box-shadow: none !important;
  }
}

.main-slider {

  &-item {
    height: calc(100vh - (#{$height-header-upper} + #{$height-header-lower} + #{$height-header-gnav}));

    &:not(:first-child) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/* Pickup & Ranking */
.section-pickup,
.section-ranking {

  .item-list {
    margin-bottom: -5.6rem;
  }
}

.section-news {
  table {
    border-bottom: 1px solid #EEE;

    .woong {
      padding: 2.5rem 0;
      font-size: 1.4rem;
      line-height: 1.71429;
      vertical-align: middle;

      &:first-child {
        padding: 2rem 2rem 2rem 0;
        max-width: 11rem;
        color: #666;
        letter-spacing: 0.05em;
      }
    }

    .line {
      display: none;
    }
  }
}


/* --------------------------------------------------
CATEGORY
-------------------------------------------------- */
.page-category {
  margin-bottom: 16rem;
}

/* --------------------------------------------------
DETAIL
-------------------------------------------------- */
.page-detail {
  margin-bottom: 16rem;
}

.detail-item-head {
  display: flex;
  margin-bottom: 8rem;

  &__images {
    position: relative;
    width: 68rem;

    > a img,
    > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    .M_imageMainList{
      opacity: 0;
    }

    .M_imageMainList-item {
      img {
        margin: auto;
      }
    }

    .M_imageMainList-item:not(:first-child) {
      display: none;
    }

    .bx-wrapper {
      margin-bottom: 1rem;
    }


    .M_imageThumbnail {
      display: flex;
      flex-wrap: wrap;
      margin: -.4rem;
    }

    .M_imageThumbnail-item {
      display: block;
      flex-basis: percentage(86 / 688);
      max-width: percentage(86 / 688);
      padding: .4rem;
    }

    .M_imageThumbnail-item img {
      width: auto;
      max-width: 100%;
    }
  }

  &__information {
    flex: 1 1 0%;
    padding-left: 4rem;
  }

  .item-maker {
    line-height: (20 / 12);
    margin-bottom: .8rem;
    color: #999999;
    font-size: 1.2rem;
    letter-spacing: (50 / 1000)+em;
  }

  .item-name {
    line-height: (36 / 26);
    margin-bottom: 1.5rem;
    font-size: 2.6rem;
    letter-spacing: (40 / 1000)+em;
  }

  .item-detail-info {
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid #E5E5E5;
  }

  // セール期間表示
  .sale-term {
    margin-bottom: 4rem;
    padding: 0.75rem 1rem;
    font-size: 1.4rem;
    line-height: (24 / 14);
    color: #da2046;
    border: 1px solid #da2046;
    text-align: center;
  }

  // セールディスカウント表示
  .sale-discount {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    // 通常価格
    .regular-price {
      color: #999;
      font-size: 1.4rem;
      line-height: (20 / 12);

      .line-through {
        text-decoration: line-through;
      }
    }

    .per-off {
      display: inline-flex;
      padding: 0 1rem;
      margin-left: 1.5rem;
      color: #FFF;
      letter-spacing: (50 / 1000)+em;
      background-color: #da2046;
      white-space: nowrap;
    }
  }

  // 希望小売価格
  .marked-price {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    line-height: (20 / 12);
  }

  .item-price {
    font-size: 2.4rem;
    letter-spacing: (50 / 1000)+em;

    > span {
      color: #999;
      font-size: 1.4rem;
    }

    input.m_price {
      padding: 0;
      font-family: $font-family-base;
      font-size: 2.4rem !important;
      color: #333;
      letter-spacing: (50 / 1000)+em;
      text-align: left;
      appearance: none;
      vertical-align: top;
    }
  }

  // 販売価格
  .sale-price {
  }

  .item-sup {
    display: flex;
    flex-wrap: wrap;
    line-height: (18 / 12);
    margin: 5.4rem 0 0;
    font-size: 1.2rem;

    > dt {
      align-self: flex-start;
      flex-basis: 12rem;
      max-width: 12rem;
      margin-bottom: 1.5rem;
      padding: .6rem;
      background-color: #EAEAEA;
      text-align: center;
    }

    > dd {
      flex-basis: calc(100% - 14rem);
      flex-grow: 1;
      margin-bottom: 1.5rem;
      padding: .6rem 0 0 2rem;
    }
  }

  .item-detail-option {
    margin-bottom: 4rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid #E5E5E5;

    ul {
      display: table;
      min-width: 40rem;

      > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        //+ li {
        //  margin-top: 1.5rem;
        //}

        table td {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: auto;
          padding-bottom: 1.5rem;

          &:empty {
            width: 0;
            padding-bottom: 0;
          }
        }
      }
    }

    select {
      height: 4rem;
      margin-left: 2rem;
      padding: 0 3rem 0 .9rem;
      border: 1px solid #707070;
      border-radius: .4rem;
      background: #fff url(../img/common/ico_angle_down.svg) no-repeat right 1.5rem center;
      background-size: 12px 6px;
      appearance: none;
    }

    select::-ms-expand {
      display: none;
    }
  }

  .item-basket-btn {
    margin: 0;

    .list-quantity {
      display: flex;
      align-items: center;

      > li {
        margin-left: 1rem;

        &:first-child {
          margin: 0 2rem 0 0;
        }

        &.count-down {

          a {

            &:before {
              content: '\f068';
            }
          }
        }

        &.count-up {

          a {

            &:before {
              content: '\f067';
            }
          }
        }
      }

      a {
        display: block;
        position: relative;
        width: 4rem;
        height: 4rem;
        line-height: 1;
        overflow: hidden;
        color: #FFF;
        background-color: #999;
        text-decoration: none;
        text-indent: 100%;
        white-space: nowrap;
        @include transition-base(all, .2s);

        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 1em;
          height: 1em;
          margin: auto;
          font-size: 2rem;
          font-family: 'Line Awesome Free';
          font-weight: 900;
          text-indent: 0;
        }

        &:active {
          color: #999;
          background-color: #EFEFEF;
        }
      }
    }

    input[name="amount"],
    input[name="brand_name_option_amount"]{
      width: 6rem;
      height: 4rem;
      padding: .4rem 1.2rem;
      border: 1px solid #999;
      font-size: 1.6rem;
      text-align: right;
    }
  }

  .item-incart {
    margin-top: 6rem;
  }

  .btn-cart {
    margin-bottom: 3rem;

    .button {
      width: 100%;
      max-width: 50rem;
      height: 6rem;
      font-size: 1.8rem;

      .las {
        margin-right: .8rem;
        font-size: 2.6rem;
      }

      &:hover {
        color: #000;
        background-color: #fff;
      }
    }
  }

  .list-sub {
    display: flex;
    max-width: 52rem;
    margin: 0 -1rem;

    > li {
      flex-basis: 50%;
      padding: 0 1rem;
    }

    .button {
      width: 100%;
      height: 5rem;
      font-size: 1.4rem;

      .las {
        margin-right: 1rem;
        font-size: 1.8rem;
      }

      &:hover {
        color: #fff;
        background-color: #000;
      }
    }
  }
}

// ウインドウを狭くしたときの調整
@media only screen and (max-width: 1200px) {

  .detail-item-head {

    &__images {
      flex-basis: percentage(680 / 1400);
      max-width: percentage(680 / 1400);
      width: percentage(680 / 1400);
    }

    &__information {
      flex-basis: percentage(720 / 1400);
      max-width: percentage(720 / 1400);
    }
  }
}

//959px以下
@include mediaquery-tablet() {
  .detail-item-head{
    .item-detail-option{
      ul{
        min-width: 100%;
      }
    }
  }
}

/* Item Decription */
.detail-item-decription {
  line-height: (24 / 14);
  margin-bottom: 9rem;

  h2 {
    line-height: (38 / 22);
    margin-bottom: (30 / 22)+em;
    padding-left: (19 / 22)+em;
    border-left: 1px solid #000;
    font-size: 2.2rem;
  }

  h3 {
    line-height: (26 / 18);
    margin-bottom: (22 / 18)+em;
    font-size: 1.8rem;
  }

  p {
    margin-bottom: (24 / 14)+em;
  }

  ul, ol, dl {
    margin-bottom: (24 / 14)+em;

    ul, ol, dl {
      margin-bottom: 0;
    }
  }

  ul {
    padding-left: (20 / 14)+em;

    li {
      list-style-type: disc;
    }
  }

  ol {
    padding-left: (20 / 14)+em;

    li {
      list-style-type: decimal;
    }
  }

  dl {

    > dd {
      margin-bottom: (10 / 14)+em;
    }
  }

  .box-spec {
    margin-bottom: (24 / 14)+em;
    padding: 2rem;
    background-color: #EFEFEF;

    > dl {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: (10 / 14 * -1)+em;

      > dt {
        flex-basis: 12rem;
      }

      > dd {
        flex-basis: calc(100% - 14rem);
        flex-grow: 1;
        padding-left: 2rem;
        min-height: 2.5rem;
      }
    }
  }
}

/* Recommend & Recent */
.detail-item-recommend,
.detail-item-recent {
  margin-bottom: 9rem;

  .item-list {
    margin-bottom: -5.6rem;
  }

  // カルーセルにした場合のスタイル
  .bx-wrapper .bx-viewport {
    box-shadow: none;
  }

  .bx-wrapper .bx-controls-direction a {
    top: 0;
    margin-top: percentage(116 / 1400);
  }
}


/* --------------------------------------------------
SEARCH
-------------------------------------------------- */
.page-search {
  margin: 10rem 0 16rem;
}


/* --------------------------------------------------
CART
-------------------------------------------------- */
.page-cart {
  margin: 10rem 0 16rem;

  .button {
    height: 5rem;
    padding: 0 2rem;
    font-size: 1.4rem;

    &-small {
      height: 3rem;
      padding: 0 1rem;
    }
  }

  .basket {
    margin-bottom: 30px;
    border-top: 1px solid #EFEFEF;

    th,
    td {
      padding: 1.5rem 1rem;
      border-bottom: 1px solid #EFEFEF;
      vertical-align: middle;
    }

    .basket-img {
      width: 60px;

      a {
        overflow: hidden;
        display: block;
        width: 60px;
        height: 60px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .basket-option {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 10px;

      select {
        margin: 0 20px 0 10px;
      }
    }

    .basket-total {
      font-size: 1.8rem;
      background: #EFEFEF;

      .total-wrap {
        .total-item {

        }

        .total-price {
          letter-spacing: 0.05em;
        }
      }
    }
  }

  .btn-wrap-back {
  }

  .btn-wrap-order {
    .button-a {
      font-size: 1.6rem;
    }
  }

  // Wish List
  .section-wishlist {
    margin-top: 16rem;

    .item-list {
      form {
        flex-basis: percentage(284 / 1420);
        max-width: percentage(284 / 1420);
        margin-bottom: 5.6rem;
        padding: 0 1rem;
        text-align: center;
      }

      .item-list-img {
        img {
          width: 100%;
          height: auto;
        }
      }

      .item-list-detail {
        .price {
          b {
            font-weight: normal;
          }
        }

        .quantity,
        .stock {
          display: flex;
          align-items: center;
          text-align: left;
          margin-top: 2rem;

          dt {
            flex-basis: 4em;
          }

          dd {
            flex-basis: calc(100% - 4em);
          }
        }
      }

      .item-list-button {
        margin-top: 3rem;

        li {
          width: 100%;
          margin-bottom: 1.5rem;

          .button-a {
            width: 100%;
          }

          .button-b {
            width: 100%;
            font-size: 1.2rem;
            height: 4rem;
          }
        }
      }
    }
  }
}



/* --------------------------------------------------
NEWS
-------------------------------------------------- */
.page-news {
  margin: 10rem 0 16rem;

  .news-list {
    max-width: initial;
    border-top: none;

    table {
      .lims {
        padding: 2rem 1rem;
      }
    }
  }

  .button {
    height: 5rem;
    padding: 0 2rem;
  }
}


/* --------------------------------------------------
ORDER CONTRACT
-------------------------------------------------- */
#M_ordercontract {
  width: 60% !important;
  margin: 10rem auto 16rem;
}


/* --------------------------------------------------
SHOW ROOM
-------------------------------------------------- */
.page-showroom {
  margin-bottom: 16rem;

  &-lead {
    line-height: (28 / 14);
    margin-bottom: 11rem;
    text-align: center;
  }
}

.showroom-list {

  .showroom-info {
    margin-bottom: 8rem;

    &:nth-child(2n) {

      .showroom-image {
        order: 1;
      }

      .showroom-body {
        order: 0;
      }
    }
  }
}

.showroom-info {
  display: flex;
  align-items: center;
  margin: 0 -2rem;

  .showroom-image,
  .showroom-body {
    flex-basis: 50%;
    padding: 0 2rem;
  }

  .showroom-image {
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .showroom-name {
    line-height: (38 / 26);
    margin-bottom: 1.4rem;
    font-size: 2.6rem;
  }

  .showroom-data {
    display: flex;
    flex-wrap: wrap;

    > dt {
      align-self: flex-start;
      flex-basis: 8rem;
      max-width: 8rem;
      line-height: (19 / 13);
      margin: 1rem 0;
      padding: .3rem .9rem .2rem;
      background-color: #EFEFEF;
      font-size: 1.3rem;
    }

    > dd {
      flex-basis: calc(100% - 14rem);
      flex-grow: 1;
      line-height: (24 / 14);
      margin: 1rem 0;
      padding: 0 0 0 1rem;
      font-size: 1.4rem;
    }
  }

  .showroom-text{
    margin-top:  2rem;
    font-size: 1.4rem;
  }

  .showroom-button {
    margin-top: 3rem;

    .button {
      width: 28rem;
      height: 5rem;
    }
  }
}



/* --------------------------------------------------
BRAND STORY
-------------------------------------------------- */
.page-story {
  margin-bottom: 16rem;
}

.brand-story {
  $this: &;
  display: flex;
  align-items: center;

  &-image {
    flex-basis: percentage(560 / 1400);
    max-width: 56rem;
    position: relative;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &-body {
    flex-basis: percentage(840 / 1400);
    max-width: 84rem;
  }

  &-headihg {
    line-height: (60 / 46);
    font-size: 4.6rem;
    letter-spacing: (100 / 1000)+em;
    @extend %font-en;
  }

  &-text {
    line-height: (32 / 14);
    font-size: 1.4rem;

    p + p {
      margin-top: (20 / 14)+em;
    }
  }

  &-sub-text {
    line-height: (32 / 14);
    font-size: 1.4rem;
    @extend %font-en;
  }

  &-01 {
    margin-top: 8rem;
    margin-bottom: 12.5rem;

    #{$this}-image {

      &:after {
        content: '';
        position: absolute;
        bottom: percentage(40 / 880 * -1);
        right: percentage(80 / 560 * -1);
        z-index: -1;
        width: percentage(560 / 560);
        height: percentage(760 / 880);
        background-color: #E0E0E0;
      }
    }

    #{$this}-body {
      padding-top: percentage(78 / 1400);
      padding-left: percentage(160 / 1400);
    }

    #{$this}-headihg {
      margin-bottom: 6rem;
    }

    #{$this}-text {
      margin-bottom: 8rem;
      padding-left: percentage(40 / 680);
    }

    #{$this}-sub-text {
      padding-left: percentage(80 / 680);
      letter-spacing: (100 / 1000)+em;
    }
  }

  &-02 {
    margin-top: 14rem;
    margin-bottom: 8rem;

    #{$this}-image {
      order: 1;

      &:after {
        content: '';
        position: absolute;
        top: percentage(40 / 760 * -1);
        left: percentage(80 / 560 * -1);
        z-index: -1;
        width: percentage(560 / 560);
        height: percentage(720 / 760);
        background-color: #E0E0E0;
      }
    }

    #{$this}-body {
      order: 0;
      padding-right: percentage(160 / 1400);
    }

    #{$this}-headihg {
      margin-bottom: 6rem;
    }

    #{$this}-sub-text {
      margin-bottom: 7rem;
      padding-left: percentage(40 / 680);
      letter-spacing: (40 / 1000)+em;
    }

    #{$this}-text {
      padding-left: percentage(80 / 680);
    }
  }
}

// ウインドウを狭くしたときの調整
@media only screen and (max-width: 1200px) {

  .brand-story {

    &-headihg {

      br {
        display: none;
      }
    }
  }
}

.brand-movie {
  max-width: 1240px;
  margin: 0 auto 14rem;

  &-heading {
    line-height: (60 / 46);
    margin-bottom: 5.3rem;
    font-size: 4.6rem;
    letter-spacing: (60 / 1000)+em;
    @extend %font-en;
  }

  &-frame {
    position: relative;
    max-width: percentage(980 / 1240);
    margin: 0 auto;

    &:before {
      content: '';
      display: block;
      padding-top: percentage(315 / 560);
    }

    &:after {
      content: '';
      position: absolute;
      top: percentage(80 / 551 * -1);
      left: percentage(40 / 980 * -1);
      z-index: -1;
      width: percentage(800 / 980);
      height: percentage(470 / 551);
      background-color: #E0E0E0;
    }

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.brand-image {
  text-align: center;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}


/* --------------------------------------------------
guide
-------------------------------------------------- */

.page-guide {
  margin-bottom: 16rem;
}

.maintenance{

  .M_guideNav{
    font-size: 0;
    white-space: normal;
    width: 100%;
    margin: 56px auto 64px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;

    li{
      display: inline-block;
      padding: 2px 16px;
      border-right: dotted 1px #AAA;

      &:last-child{
        border-right: none;
      }
    }

    a{
      font-size: 15px;
      color: #333;
      text-decoration: none;
    }
  }

  h3{
    line-height: (38 / 22);
    margin-bottom: (30 / 22)+em;
    padding-left: (19 / 22)+em;
    border-left: 1px solid #000;
    font-size: 2.2rem;
  }

  h4{
    line-height: (26 / 18);
    margin-bottom: (22 / 18)+em;
    font-size: 1.8rem;
  }

  ul {
    padding-left: (20 / 14)+em;

    li {
      list-style-type: disc;
    }
  }

  ol {
    padding-left: (20 / 14)+em;

    li {
      list-style-type: decimal;
    }
  }

  .guide-block{
    margin-bottom: 6rem;

    &-small{
      margin-bottom: 2.5rem;
    }

    &-wrapper{
      margin-bottom: 8rem;
    }
  }


  .col-2{
    display: flex;
    justify-content: space-between;

    .cols{
      flex-basis: 50%;

      &:nth-child(odd){
        padding-right: 1%;
      }
      &:nth-child(even){
        padding-left: 1%;
      }
    }

    .tbl{
      width: 100%;
    }
  }

  .tbl{
    border-collapse: collapse;
    border: solid 1px #707070;

    th,td{
      padding: 1rem 2rem;
      border: solid 1px #707070;
      font-size: 1.3rem;
      vertical-align: middle;
    }

    th{
      background-color: #EFEFEF;
    }

    thead{
      td{
        text-align: center;
      }
    }

    img{
      max-width: 100%;
    }

    p{
      margin-top: 1em;
    }
  }
}

/* --------------------------------------------------
GUIDE - MakeShop Built-in Page
-------------------------------------------------- */
#guide{
  .inner-contents{
    width: 100%;

    pre{
      white-space: pre-wrap;
    }
  }
}


/* --------------------------------------------------
COMPANY - MakeShop Built-in Page
-------------------------------------------------- */
.page-about {
  width: 60% !important;
  margin: 10rem auto 16rem;

  p {
    margin-bottom: 2em;
  }
}
