@charset "utf-8";

// ----
// * clearfix
// * ex) @include clearfix();
@mixin clearfix() {
  &:after {
    display: block;
    clear: both;
    content: '';
  }
}

// ----
// * flexbox
// * ex) @include flexbox();
@mixin flexbox() {
  display: flex;
  flex-wrap: wrap;
}

// ----
// * transition 基本設定 mixin
// * ex) @include transition-base(background-color, 1s);
@mixin transition-base($property: null, $duration: .3s,  $timing: ease, $delay: 0s) {
  transition: $property $duration $timing $delay;
}

// ----
// * センタリングするテキストに letter-spacing を指定する場合の微調整
// * ex) @include ls-center(200);
@mixin ls-center($va: 40) {
  text-indent: ($va / 1000)+em;
  letter-spacing: ($va / 1000)+em;
}

// ----
// * メディアクエリ 分岐mixin
// * ex)
//   @include mediaquery-sp() {
//     width: 100%;
//     font-size: 100%;
//   }
@mixin mediaquery-iphone5() {
  @media only screen and (max-width: 320px) {
    @content;
  }
}

@mixin mediaquery-sp() {
  @media only screen and (max-width: $width-sp) {
    @content;
  }
}

@mixin mediaquery-lt-tablet() {
  @media only screen and (max-width: $width-tablet) {
    @content;
  }
}

@mixin mediaquery-tablet() {
  @media only screen and (min-width: $width-tablet) and (max-width: $width-pc - 1) {
    @content;
  }
}

@mixin mediaquery-pc() {
  @media only screen and (min-width: $width-pc ) {
    @content;
  }
}

// ----
// * フォントサイズrem指定 [IE8はpx]
// * ex) @include fontsize-rem(1.6);
@mixin fontsize-rem($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}


// ----
// * フォントサイズ viewport width 変換
// * ex) @include fz_vw(13);
@function get_vw($size, $viewport:375){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}


// ----
// * 1行文字制限
// * ex) @include ellipsis();
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

// ----
// * 1行文字制限
// * ex) @include ellipsis();
@mixin opacity($alpah) {
  opacity: $alpah;
}


// ----
// * 画像置換
// * @width: 画像横幅
// * @height: 画像高さ
// * ex) @img-replace(20,14)
@mixin img-replace($width,$height) {
    overflow: hidden;
    width: $width;
    height: $height;
    text-indent: 100%;
    white-space: nowrap;
}


// ----
// * フォントサイズ相対関数
// * @size: 画像横幅
// * ex) fz(10) => 83%;













