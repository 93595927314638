@charset "UTF-8";


/*
  ##### online.fisba.co.jp #####

  [Base]
  - Reset
  - Init
  [Layout]
  - Container
  - Header
  - Content
  - Footer
  [Module]

*/
/* =========================================================
[Base]
========================================================= */
/* --------------------------------------------------
  0. Reset
-------------------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
p,
blockquote,
th,
td,
form,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  margin: 0;
  padding: 0;
}

body {
  line-height: 1;
}

div {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  line-height: 1.2;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

caption,
th,
td {
  text-align: left;
  vertical-align: top;
  font-weight: normal;
}

fieldset {
  border: none;
}

input,
textarea,
select,
label {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: middle;
}

label {
  cursor: pointer;
}

textarea {
  overflow: auto;
}

/* --------------------------------------------------
  1. Init
-------------------------------------------------- */
img {
  border: none;
  vertical-align: bottom;
}

picture {
  display: block;
}

object {
  vertical-align: middle;
  outline: none;
}

em,
strong {
  font-weight: bold;
  font-style: normal;
}

small {
  font-size: 100%;
}

abbr,
acronym {
  border: none;
  font-variant: normal;
}

q:before,
q:after {
  content: '';
}

address,
caption,
cite,
code,
dfn,
var {
  font-weight: normal;
  font-style: normal;
}

code,
pre {
  font-family: "Poppins", "Noto Sans JP", sans-serif, monospace;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  word-break: normal;
}

/* =========================================================
[Layout]
========================================================= */
/* //////////////////////////////////////////////////
[Layout]
////////////////////////////////////////////////// */
html {
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 62.5%;
}

body {
  color: #333333;
  font-size: 1.0rem;
  line-height: 1.5;
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #ffffff;
  border-top: none;
}

a:link,
a:visited {
  color: #000;
  text-decoration: underline;
}

a:hover,
a:active {
  text-decoration: none;
}

/* --------------------------------------------------
  Container
-------------------------------------------------- */
.l-container {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

/* --------------------------------------------------
  Header
-------------------------------------------------- */
.l-header {
  background-color: #EFEFEF;
  text-align: left;
}

.header-upper {
  height: 4rem;
  color: #fff;
  background-color: #000;
}

.header-upper .l-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-lower {
  height: 14.6rem;
}

.header-lower .l-container {
  position: relative;
  height: 100%;
  padding-top: 3.5rem;
}

.header-tagline {
  line-height: 1.54545;
  margin: 0 auto 0 0;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-utility-menu {
  display: flex;
  height: 100%;
  border-left: 1px solid #3B3B3B;
}

.header-utility-menu > li {
  height: 100%;
  border-right: 1px solid #3B3B3B;
}

.header-utility-menu .button {
  height: 100%;
  line-height: 1;
  padding: 0 1.9rem;
  font-size: 1.3rem;
  white-space: nowrap;
}

.header-utility-menu .button:link, .header-utility-menu .button:visited {
  color: #fff;
}

.header-utility-menu .button:hover {
  opacity: .7;
}

.header-utility-menu .button .las {
  margin-right: .5rem;
  color: #777;
  font-size: 1.8rem;
}

.site-logo {
  margin: 0 auto;
  text-align: center;
}

.site-logo > a {
  text-decoration: none;
}

.site-logo > a:link, .site-logo > a:visited {
  color: #222221;
}

.site-logo .svg-use {
  display: inline-block;
  width: 28.2rem;
  height: 9.3rem;
  color: #222221;
}

.header-nav {
  position: static;
}

.header-nav li {
  display: block;
  margin: 0;
}

.header-function {
  position: absolute;
  top: 5rem;
  right: 2rem;
}

.header-function-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 27rem;
}

.header-function .header-function-members {
  display: flex;
  align-items: center;
  width: 19rem;
}

.header-function .header-function-members .las {
  margin-right: .5rem;
  font-size: 2.2rem;
}

.header-function .header-function-members .list-member {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.3em;
}

.header-function .header-function-members .list-member li {
  margin: 0;
}

.header-function .header-function-members .list-member li a {
  position: relative;
  font-size: 1.2rem;
}

.header-function .header-function-members .list-member .username {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 1.3rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999;
}

.header-function .header-function-members a {
  display: inline-block;
  line-height: 1.46154;
  font-size: 1.3rem;
  text-decoration: none;
  transition: 0.3s ease 0s;
}

.header-function .header-function-members a:hover {
  opacity: .7;
}

.header-function-cart {
  width: 7rem;
}

.header-function-cart #M_basketTemp1 #M_basketClosedBox a {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 0;
  background-color: transparent;
}

.header-function-cart #M_basketTemp1 #M_basketClosedBox a:before {
  content: '\f07a';
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  line-height: 1;
  margin-right: .5rem;
  font-size: 2.2rem;
  font-family: 'Line Awesome Free';
  font-weight: 900;
}

.header-function-cart #M_basketTemp1 #M_basketClosedBox a:after {
  content: 'カート';
  display: inline-block;
  line-height: 1.46154;
  font-size: 1.3rem;
}

.header-function-cart #M_basketTemp1 #M_basketClosedBox a:link, .header-function-cart #M_basketTemp1 #M_basketClosedBox a:visited {
  color: #000;
}

.header-function-cart #M_basketClosedBox .M_basketMarginBox {
  display: none;
}

.header-function .header-function-search {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1.8rem;
}

.header-function #MakeShopTopSearchInput {
  width: calc(100% - 3rem);
  height: 3rem;
  border: none;
}

.header-function #MakeShopTopSearchButton {
  position: relative;
  width: 3rem;
  height: 3rem;
  margin: 0;
  border-radius: 0;
  color: #fff;
  background-image: none;
  text-decoration: none;
}

.header-function #MakeShopTopSearchButton:before {
  content: '\f002';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 1em;
  height: 1em;
  line-height: 1em;
  margin: auto;
  font-size: 1.8rem;
  font-family: 'Line Awesome Free';
  font-weight: 900;
  text-indent: 0;
}

/* --------------------------------------------------
  Humburger Button
-------------------------------------------------- */
/* --------------------------------------------------
  Global Nav
-------------------------------------------------- */
.header-gnav {
  display: flex;
  justify-content: center;
  position: relative;
  height: 5.4rem;
  line-height: 1.42857;
  font-size: 1.4rem;
}

.header-gnav > li {
  height: 100%;
}

.header-gnav > li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease 0s;
}

.header-gnav > li:hover > a:after {
  transform: scaleX(1);
  opacity: 1;
}

.header-gnav > li > a {
  display: block;
  position: relative;
  height: 100%;
  padding: 1.8rem 2.5rem 0;
}

.header-gnav > li > a:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: calc(100% - 3.8rem);
  height: 1px;
  margin: 0 auto;
  background-color: #000;
  transform: scaleX(0.3);
  opacity: 0;
  transition: 0.3s ease 0s;
}

.header-gnav a {
  position: relative;
  text-decoration: none;
  transition: 0.3s ease 0s;
}

.mega-menu {
  position: absolute;
  top: 5.4rem;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: 0.15s ease 0s;
}

.mega-menu-row {
  display: flex;
  justify-content: center;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

.mega-menu-image, .mega-menu-col {
  flex-basis: 33.33333%;
  max-width: 33.33333%;
}

.mega-menu-col {
  padding: 6rem 2rem 0;
}

.mega-menu-image {
  text-align: center;
}

.mega-menu-image img {
  max-width: 100%;
  height: auto;
}

.mega-menu-title {
  line-height: 1.75;
  padding-left: 1.1875em;
  border-left: 1px solid #000;
  font-size: 1.6rem;
}

.sub-menu {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.57143;
  margin: -.4rem -1rem 0;
  font-size: 1.4rem;
}

.sub-menu > li {
  flex-basis: 50%;
  max-width: 50%;
  margin-top: 2.4rem;
  padding: 0 1rem;
}

.sub-menu a {
  display: inline-block;
  padding-left: 1.07143em;
  transition: 0.3s ease 0s;
}

.sub-menu a:before {
  content: '\f138';
  position: absolute;
  top: 0;
  left: -1px;
  color: #707070;
  font-family: 'Line Awesome Free';
  font-weight: 900;
}

.sub-menu a:hover {
  opacity: .7;
}

.sub-menu.menu-bottom {
  margin-top: 4.2rem;
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .mega-menu-image {
    position: relative;
    overflow: hidden;
  }
  .mega-menu-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: none;
  }
  .mega-menu-col {
    padding: 4rem 2rem 1rem;
  }
}

/* --------------------------------------------------
  Contents
-------------------------------------------------- */
.l-contents {
  text-align: left;
  font-size: 1.4rem;
}

/* --------------------------------------------------
  Footer
-------------------------------------------------- */
.l-footer {
  padding: 0 0 6.2rem;
  color: #fff;
  background-color: #000;
  text-align: left;
}

.footer-utility {
  background-color: #EFEFEF;
}

.footer-utility-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  line-height: 1.71429;
  font-size: 1.4rem;
}

.footer-utility-menu > li {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 6rem;
  border-right: 1px solid #B5B5B5;
}

.footer-utility-menu > li:first-child {
  border-left: 1px solid #B5B5B5;
}

.footer-utility-menu a {
  text-decoration: none;
  transition: 0.3s ease 0s;
}

.footer-utility-menu a:hover {
  opacity: .7;
}

.footer-utility-menu .las {
  position: relative;
  top: .2rem;
  margin-right: .5rem;
  color: #777777;
  font-size: 1.8rem;
}

.footer-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24.7em;
  background: url(../img/common/bg_footer_info.jpg) no-repeat center center;
  background-size: cover;
}

.footer-info-box {
  display: flex;
  align-items: center;
}

.footer-info-logo {
  width: 28rem;
}

.footer-info-body {
  line-height: 1.42857;
  padding-left: 4.2rem;
  font-size: 1.4rem;
}

.footer-info-button {
  margin-top: 1.7rem;
}

.footer-info-button .button {
  width: 22rem;
  height: 5rem;
  margin: 0;
}

.footer-nav {
  display: flex;
  margin: 0 -2rem;
  padding: 8rem 0 7rem;
  color: #fff;
  background-color: transparent;
}

.footer-nav-col {
  width: 30.55556%;
  min-width: 37rem;
  padding: 0 2rem;
}

.footer-nav-sns {
  position: absolute;
  top: 8rem;
  right: 2rem;
}

.footer-nav-heading {
  line-height: 1.5;
  margin: 0 0 2.4rem;
  font-size: 1.6rem;
}

.footer-nav-menu-row {
  display: flex;
  margin: 0 -1rem;
}

.footer-nav-menu-row .footer-nav-menu {
  width: 50%;
  padding: 0 1rem;
}

.footer-nav-menu {
  line-height: 1.5;
  font-size: 1.2rem;
}

.footer-nav-menu > li {
  margin-bottom: 1rem;
}

.footer-nav-menu .las {
  position: relative;
  top: .1rem;
  font-size: 1.6rem;
}

.footer-nav-menu a {
  text-decoration: none;
  transition: 0.3s ease 0s;
}

.footer-nav-menu a:link, .footer-nav-menu a:visited {
  color: #fff;
}

.footer-nav-menu a:hover {
  opacity: .7;
}

.footer-sns-list {
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 0 -1.5rem;
  font-size: 2.8rem;
}

.footer-sns-list .la-instagram {
  font-size: 3rem;
}

.footer-sns-list > li {
  padding: 0 1.5rem;
}

.footer-sns-list a {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.3s ease 0s;
}

.footer-sns-list a:link, .footer-sns-list a:visited {
  color: #fff;
}

.footer-sns-list a:hover {
  opacity: .7;
}

.footer-copyright {
  font-size: 1.2rem;
  text-align: center;
  text-indent: 0.05em;
  letter-spacing: 0.05em;
}

/* =========================================================
[Module]
========================================================= */
/* //////////////////////////////////////////////////
[Module]
////////////////////////////////////////////////// */
/* --------------------------------------------------
  Clearfix
-------------------------------------------------- */
.group:after {
  display: block;
  content: "";
  clear: both;
}

/* --------------------------------------------------
  Interaction
-------------------------------------------------- */
a img,
.hover a {
  -webkit-transition: 0.1s linear;
  transition: 0.1s linear;
  background: rgba(255, 255, 255, 0.01);
}

a:hover img,
.hover a:hover,
.hover a:hover img {
  -ms-filter: "alpha(opacity=70)";
  opacity: 0.70;
}

/* --------------------------------------------------
  Heading
-------------------------------------------------- */
.title-a {
  line-height: 1;
  margin-bottom: 4rem;
  text-align: center;
}

.title-a .en {
  display: inline-block;
  line-height: 1.3125;
  font-weight: 400;
  font-size: 3.2rem;
  text-indent: 0.1em;
  letter-spacing: 0.1em;
}

.title-a .en:after {
  content: '';
  display: block;
  width: calc(100% - 0.2em);
  height: .2rem;
  margin: 1rem auto 0;
  background-color: #000;
}

.title-a .jp {
  display: block;
  line-height: 1.53846;
  margin-top: 1.6rem;
  font-size: 1.3rem;
}

.title-b {
  line-height: 1;
  margin-bottom: 4rem;
  text-align: center;
}

.title-b .txt {
  display: inline-block;
  line-height: 1.3125;
  font-weight: 400;
  font-size: 3.2rem;
  text-indent: 0.1em;
  letter-spacing: 0.1em;
}

.title-b .txt:after {
  content: '';
  display: block;
  width: calc(100% - 0.2em);
  height: .2rem;
  margin: 1rem auto 0;
  background-color: #000;
}

/* --------------------------------------------------
  Button
-------------------------------------------------- */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  text-decoration: none !important;
  text-align: center;
  appearance: none;
  cursor: pointer;
  transition: 0.3s ease 0s;
}

.button-a {
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
}

.button-a:link, .button-a:visited {
  color: #fff;
}

.button-a:hover {
  color: #000;
  background-color: #fff;
}

.button-b {
  color: #000;
  background-color: #EDEDED;
}

.button-b:link, .button-b:visited {
  color: #000;
}

.button-b:hover {
  color: #fff;
  background-color: #000;
}

.button-ghost {
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
}

.button-ghost:link, .button-ghost:visited {
  color: #fff;
}

.button-ghost:hover {
  color: #000;
  background-color: #fff;
}

/* --------------------------------------------------
  Text
-------------------------------------------------- */
.title-a .en, .brand-story-headihg, .brand-story-sub-text, .brand-movie-heading {
  font-family: "EB Garamond", sans-serif;
}

/* --------------------------------------------------
  Image Width Fixed
-------------------------------------------------- */
/* --------------------------------------------------
  Bread Crumbs
-------------------------------------------------- */
#M_topicPathWrap {
  max-width: 1440px;
  line-height: 1.66667;
  margin: 3rem auto 7rem;
  padding-right: 2rem;
  padding-left: 2rem;
  font-size: 1.2rem;
}

#M_topicPathWrap li {
  margin-right: 0;
  padding-right: 0;
  background: none;
}

#M_topicPathWrap li:after {
  content: '>';
  display: inline-block;
  margin: 0 0.5em;
  color: #666;
  font-family: 'Noto Sans JP', sans-serif;
}

#M_topicPathWrap li.M_current:after {
  content: none;
}

#M_topicPathWrap li.M_current a {
  text-decoration: none;
}

/* --------------------------------------------------
  Local Nav
-------------------------------------------------- */
/* --------------------------------------------------
  List
-------------------------------------------------- */
/* --------------------------------------------------
  Link
-------------------------------------------------- */
/* --------------------------------------------------
  Section
-------------------------------------------------- */
/* --------------------------------------------------
  Box
-------------------------------------------------- */
/* --------------------------------------------------
  Table
-------------------------------------------------- */
/* --------------------------------------------------
  Form
-------------------------------------------------- */
/* --------------------------------------------------
  Responsive Grid
-------------------------------------------------- */
/* --------------------------------------------------
  Block Model
-------------------------------------------------- */
/* --------------------------------------------------
  Item List
-------------------------------------------------- */
.category-list-inner {
  padding: 0;
}

.item-list img {
  margin-bottom: 0;
  border-radius: 0;
}

.item-list a {
  font-size: 100%;
}

/* 汎用商品リスト */
.item-list {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.42857;
  margin: 0 -1rem;
  font-size: 1.4rem;
}

.item-list > li {
  flex-basis: 20%;
  max-width: 20%;
  margin-bottom: 5.6rem;
  padding: 0 1rem;
  text-align: center;
}

.item-list a {
  text-decoration: none;
}

.item-list-img {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.6rem;
}

.item-list-img:before {
  display: block;
  content: "";
  padding-top: 100%;
}

.item-list-img img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.item-list .name {
  margin-bottom: 1.8rem;
}

.item-list .name > a:hover {
  opacity: .7;
}

.item-list .price {
  line-height: 1.375;
  margin-top: .7rem;
  font-weight: normal;
  font-size: 1.6rem;
}

.item-list .price span {
  font-size: 1.4rem;
}

/* ランキングリスト */
.rank-list > li {
  position: relative;
}

.rank-number {
  top: 10px;
  left: 20px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-weight: 400;
  font-size: 1.3rem;
}

/* --------------------------------------------------
  Category Page Module
-------------------------------------------------- */
/* サブカテゴリーリスト */
.category-sublist {
  margin: 10rem -1rem 8rem;
}

.category-sublist table {
  width: auto;
}

.category-sublist table .line {
  height: 0;
}

.category-sublist table td {
  width: auto;
}

.category-sublist table img {
  display: none;
}

.category-sublist table a {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 26.4rem;
  height: 5rem;
  padding: 0 2rem;
  border-radius: .4rem;
  background-color: #EFEFEF;
  font-size: 1.4rem;
  text-decoration: none;
  transition: 0.3s ease 0s;
}

.category-sublist table a:after {
  content: '\f105';
  position: absolute;
  top: 0;
  bottom: 0;
  right: .6rem;
  width: 1em;
  height: 1.42857em;
  margin: auto 0;
  font-family: 'Line Awesome Free';
  font-weight: 900;
}

.category-sublist table a:hover {
  color: #fff;
  background-color: #000;
}

.category-sublist table > tbody tr:nth-child(n + 2) > td {
  width: 20%;
  padding: 0 1rem 2rem;
}

#makebanner td[width="640"] .category-sublist > table {
  width: 100%;
}

/* カテゴリーページ UI */
.category-sort {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.category-sort .list-sort-select {
  margin: 0 auto 0 0;
}

.category-sort .list-sort {
  margin: 0;
}

.category-sort .list-total {
  margin-left: auto;
}

.category-sort .list-pager {
  margin-left: 2rem;
}

/* トータル */
.list-total {
  line-height: 1.375;
  font-size: 1.6rem;
}

/* ソート */
.list-sort-select {
  position: relative;
  width: 20rem;
  height: 4rem;
}

.list-sort-select:after {
  content: '\f107';
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 12;
  right: 1.4rem;
  margin: auto 0;
  width: 1em;
  height: 1.5em;
  font-family: 'Line Awesome Free';
  font-weight: 900;
  transition: 0.3s ease 0s;
}

.list-sort-select .list-sort-display {
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 11;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  padding: 0 3rem 0 1rem;
  border-radius: .4rem;
  background-color: #fff;
  transition: 0.3s ease 0s;
}

.list-sort-select .list-sort {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.list-sort-select .list-sort li {
  display: block;
  margin: 0;
  transition: 0.3s ease 0s;
}

.list-sort-select .list-sort li:nth-child(n+2) {
  height: 0;
}

.list-sort-select.is-open:after {
  transform: rotate(-180deg);
}

.list-sort-select.is-open .list-sort-display {
  opacity: 0;
  visibility: hidden;
}

.list-sort-select.is-open .list-sort li:nth-child(n+2) {
  height: 3.8rem;
}

.list-sort {
  border: 1px solid #707070;
  border-radius: .4rem;
  background-color: #fff;
  text-align: left;
  overflow: hidden;
}

.list-sort a {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 3.8rem;
  padding: 0 3rem 0 1rem;
  text-decoration: none;
}

.list-sort a:hover {
  background-color: #efefef;
}

/* ページャー */
.list-pager .M_pager {
  display: flex;
  margin: 0;
}

.list-pager .M_pager li {
  display: inline-flex;
  line-height: 1.71429;
  margin-right: .5rem;
  font-size: 1.4rem;
}

.list-pager .M_pager li:last-child {
  margin-right: 0;
}

.list-pager .M_pager li.prev, .list-pager .M_pager li.next {
  position: relative;
  width: 5rem;
  height: 4rem;
  color: #999;
  background: #EFEFEF;
  text-indent: -9999px;
}

.list-pager .M_pager li.prev a, .list-pager .M_pager li.next a {
  width: 5rem;
  height: 4rem;
  color: #fff;
  background: #999999;
}

.list-pager .M_pager li.prev a:after, .list-pager .M_pager li.next a:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1em;
  height: 1.57143em;
  margin: auto;
  font-family: 'Line Awesome Free';
  font-weight: 900;
  text-indent: 0;
}

.list-pager .M_pager li.prev a:hover, .list-pager .M_pager li.next a:hover {
  color: #999;
  background: #EFEFEF;
}

.list-pager .M_pager li.prev a:after, .list-pager .M_pager li.next:first-child a:after {
  content: '\f104';
}

.list-pager .M_pager li.next a:after {
  content: '\f105';
}

.list-pager .M_pager li.active {
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  padding: 2px;
  border: 1px solid #000;
  color: #000;
  background: #fff;
  font-weight: 400;
}

.list-pager .M_pager li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  padding: 2px;
  border: 1px solid #000;
  color: #fff;
  background: #000;
  transition: 0.3s ease 0s;
}

.list-pager .M_pager li a:visited {
  color: #fff;
}

.list-pager .M_pager li a:hover {
  color: #000;
  background-color: #fff;
}

/* カテゴリーリスト */
.category-list-img {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.6rem;
}

.category-list-img:before {
  display: block;
  content: "";
  padding-top: 100%;
}

.category-list-img img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

/* --------------------------------------------------
  News List
-------------------------------------------------- */
.news-list {
  max-width: 832px;
  margin: 0 auto 5rem;
  border-top: 1px solid #EEE;
}

.news-list-item {
  display: flex;
  padding: 2.7rem 0;
  border-bottom: 1px solid #EEE;
  font-size: 1.4rem;
}

.news-list-date {
  flex-basis: 11rem;
  max-width: 11rem;
  line-height: 1.71429;
  padding-right: 1em;
  color: #666;
  letter-spacing: 0.05em;
}

.news-list-title {
  flex-grow: 1;
  line-height: 1.71429;
}

.news-list-title > a {
  text-decoration: none;
  transition: 0.3s ease 0s;
}

.news-list-title > a:hover {
  opacity: .7;
}

/* サーチ */
.item-search {
  border: none;
  background: #EFEFEF;
  border-radius: 0;
  padding: 3rem;
  margin-bottom: 4rem;
}

.item-search table {
  margin-bottom: 2rem;
}

.item-search table th {
  vertical-align: middle;
  width: 10%;
}

.item-search table td select + select {
  margin-left: 1%;
}

.item-search table input {
  width: calc( 80% + 1.5rem);
}

.item-search table #searchPrice input {
  width: 40%;
}

.item-search .btn-search {
  display: block;
  width: 22rem;
  border-radius: 0;
  text-align: center;
  position: relative;
  margin: 0 auto;
  text-decoration: none;
  padding: 1rem;
  box-sizing: border-box;
  border: solid 1px #000;
}

.item-search .btn-search:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 1em;
  font-family: 'Line Awesome Free';
  text-indent: 0;
  content: '\f002';
  transform: translate(2.8rem, -0.1rem);
}

.item-search .btn-search:hover {
  opacity: 1;
  background: #FFF;
  color: #000 !important;
}

.item-search .btn-search:hover:before {
  color: #000;
}

/* --------------------------------------------------
  SVG
-------------------------------------------------- */
.svg-symbols {
  display: none;
}

.svg-use {
  display: inline-block;
  overflow: hidden;
}

/* --------------------------------------------------
  Show / Hide / Line Break
-------------------------------------------------- */
.br-tab,
.br-sp {
  display: none;
}

.br-tab.br-pc,
.br-sp.br-pc,
.br-pc {
  display: inline-block;
}

.hidden-block-pc {
  display: none;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .br-sp.br-pc,
  .br-pc,
  .br-sp {
    display: none;
  }
  .br-pc.br-tab,
  .br-sp.br-tab,
  .br-tab {
    display: inline-block;
  }
  .hidden-block-pc.hidden-block-tab,
  .hidden-block-sp.hidden-block-tab,
  .hidden-block-tab {
    display: none;
  }
  .hidden-block-sp.hidden-block-pc,
  .hidden-block-pc,
  .hidden-block-sp {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .br-tab.br-pc,
  .br-pc,
  .br-tab {
    display: none;
  }
  .br-pc.br-sp,
  .br-tab.br-sp,
  .br-sp {
    display: inline-block;
  }
  .hidden-block-pc.hidden-block-sp,
  .hidden-block-tab.hidden-block-sp,
  .hidden-block-sp {
    display: none;
  }
  .hidden-block-tab.hidden-block-pc,
  .hidden-block-pc,
  .hidden-block-tab {
    display: block;
  }
}

/* --------------------------------------------------
  Other
-------------------------------------------------- */
/* bxSlider */
.bx-wrapper {
  border: none;
  box-shadow: none;
}

.bx-wrapper .bx-controls-direction a {
  width: 50px;
  height: 50px;
  margin-top: -25px;
}

.bx-wrapper .bx-prev {
  left: 0;
  background: url(../img/common/ico_arrow_prev_01.png) no-repeat 0 0;
}

.bx-wrapper .bx-prev:hover, .bx-wrapper .bx-prev:focus {
  background-position: 0 0;
}

.bx-wrapper .bx-next {
  right: 0;
  background: url(../img/common/ico_arrow_next_01.png) no-repeat 0 0;
}

.bx-wrapper .bx-next:hover, .bx-wrapper .bx-next:focus {
  background-position: 0 0;
}

/* Object Fit Images */
.ofi-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

/* =========================================================
[Pages]
========================================================= */
/* //////////////////////////////////////////////////
[Pages]
////////////////////////////////////////////////// */
/* --------------------------------------------------
HOME
-------------------------------------------------- */
/* Section */
.section-pickup,
.section-ranking,
.section-news {
  margin-bottom: 9rem;
}

/* Main Visual */
.main-visual {
  position: relative;
  z-index: 0;
  margin-bottom: 7rem;
}

.main-visual .bx-viewport {
  box-shadow: none !important;
}

.main-slider-item {
  height: calc(100vh - (4rem + 14.6rem + 5.4rem));
}

.main-slider-item:not(:first-child) {
  display: none;
}

.main-slider-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Pickup & Ranking */
.section-pickup .item-list,
.section-ranking .item-list {
  margin-bottom: -5.6rem;
}

.section-news table {
  border-bottom: 1px solid #EEE;
}

.section-news table .woong {
  padding: 2.5rem 0;
  font-size: 1.4rem;
  line-height: 1.71429;
  vertical-align: middle;
}

.section-news table .woong:first-child {
  padding: 2rem 2rem 2rem 0;
  max-width: 11rem;
  color: #666;
  letter-spacing: 0.05em;
}

.section-news table .line {
  display: none;
}

/* --------------------------------------------------
CATEGORY
-------------------------------------------------- */
.page-category {
  margin-bottom: 16rem;
}

/* --------------------------------------------------
DETAIL
-------------------------------------------------- */
.page-detail {
  margin-bottom: 16rem;
}

.detail-item-head {
  display: flex;
  margin-bottom: 8rem;
}

.detail-item-head__images {
  position: relative;
  width: 68rem;
}

.detail-item-head__images > a img,
.detail-item-head__images > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.detail-item-head__images .M_imageMainList {
  opacity: 0;
}

.detail-item-head__images .M_imageMainList-item img {
  margin: auto;
}

.detail-item-head__images .M_imageMainList-item:not(:first-child) {
  display: none;
}

.detail-item-head__images .bx-wrapper {
  margin-bottom: 1rem;
}

.detail-item-head__images .M_imageThumbnail {
  display: flex;
  flex-wrap: wrap;
  margin: -.4rem;
}

.detail-item-head__images .M_imageThumbnail-item {
  display: block;
  flex-basis: 12.5%;
  max-width: 12.5%;
  padding: .4rem;
}

.detail-item-head__images .M_imageThumbnail-item img {
  width: auto;
  max-width: 100%;
}

.detail-item-head__information {
  flex: 1 1 0%;
  padding-left: 4rem;
}

.detail-item-head .item-maker {
  line-height: 1.66667;
  margin-bottom: .8rem;
  color: #999999;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}

.detail-item-head .item-name {
  line-height: 1.38462;
  margin-bottom: 1.5rem;
  font-size: 2.6rem;
  letter-spacing: 0.04em;
}

.detail-item-head .item-detail-info {
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #E5E5E5;
}

.detail-item-head .sale-term {
  margin-bottom: 4rem;
  padding: 0.75rem 1rem;
  font-size: 1.4rem;
  line-height: 1.71429;
  color: #da2046;
  border: 1px solid #da2046;
  text-align: center;
}

.detail-item-head .sale-discount {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.detail-item-head .sale-discount .regular-price {
  color: #999;
  font-size: 1.4rem;
  line-height: 1.66667;
}

.detail-item-head .sale-discount .regular-price .line-through {
  text-decoration: line-through;
}

.detail-item-head .sale-discount .per-off {
  display: inline-flex;
  padding: 0 1rem;
  margin-left: 1.5rem;
  color: #FFF;
  letter-spacing: 0.05em;
  background-color: #da2046;
  white-space: nowrap;
}

.detail-item-head .marked-price {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.66667;
}

.detail-item-head .item-price {
  font-size: 2.4rem;
  letter-spacing: 0.05em;
}

.detail-item-head .item-price > span {
  color: #999;
  font-size: 1.4rem;
}

.detail-item-head .item-price input.m_price {
  padding: 0;
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  font-size: 2.4rem !important;
  color: #333;
  letter-spacing: 0.05em;
  text-align: left;
  appearance: none;
  vertical-align: top;
}

.detail-item-head .item-sup {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5;
  margin: 5.4rem 0 0;
  font-size: 1.2rem;
}

.detail-item-head .item-sup > dt {
  align-self: flex-start;
  flex-basis: 12rem;
  max-width: 12rem;
  margin-bottom: 1.5rem;
  padding: .6rem;
  background-color: #EAEAEA;
  text-align: center;
}

.detail-item-head .item-sup > dd {
  flex-basis: calc(100% - 14rem);
  flex-grow: 1;
  margin-bottom: 1.5rem;
  padding: .6rem 0 0 2rem;
}

.detail-item-head .item-detail-option {
  margin-bottom: 4rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #E5E5E5;
}

.detail-item-head .item-detail-option ul {
  display: table;
  min-width: 40rem;
}

.detail-item-head .item-detail-option ul > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.detail-item-head .item-detail-option ul > li table td {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding-bottom: 1.5rem;
}

.detail-item-head .item-detail-option ul > li table td:empty {
  width: 0;
  padding-bottom: 0;
}

.detail-item-head .item-detail-option select {
  height: 4rem;
  margin-left: 2rem;
  padding: 0 3rem 0 .9rem;
  border: 1px solid #707070;
  border-radius: .4rem;
  background: #fff url(../img/common/ico_angle_down.svg) no-repeat right 1.5rem center;
  background-size: 12px 6px;
  appearance: none;
}

.detail-item-head .item-detail-option select::-ms-expand {
  display: none;
}

.detail-item-head .item-basket-btn {
  margin: 0;
}

.detail-item-head .item-basket-btn .list-quantity {
  display: flex;
  align-items: center;
}

.detail-item-head .item-basket-btn .list-quantity > li {
  margin-left: 1rem;
}

.detail-item-head .item-basket-btn .list-quantity > li:first-child {
  margin: 0 2rem 0 0;
}

.detail-item-head .item-basket-btn .list-quantity > li.count-down a:before {
  content: '\f068';
}

.detail-item-head .item-basket-btn .list-quantity > li.count-up a:before {
  content: '\f067';
}

.detail-item-head .item-basket-btn .list-quantity a {
  display: block;
  position: relative;
  width: 4rem;
  height: 4rem;
  line-height: 1;
  overflow: hidden;
  color: #FFF;
  background-color: #999;
  text-decoration: none;
  text-indent: 100%;
  white-space: nowrap;
  transition: all 0.2s ease 0s;
}

.detail-item-head .item-basket-btn .list-quantity a:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 2rem;
  font-family: 'Line Awesome Free';
  font-weight: 900;
  text-indent: 0;
}

.detail-item-head .item-basket-btn .list-quantity a:active {
  color: #999;
  background-color: #EFEFEF;
}

.detail-item-head .item-basket-btn input[name="amount"],
.detail-item-head .item-basket-btn input[name="brand_name_option_amount"] {
  width: 6rem;
  height: 4rem;
  padding: .4rem 1.2rem;
  border: 1px solid #999;
  font-size: 1.6rem;
  text-align: right;
}

.detail-item-head .item-incart {
  margin-top: 6rem;
}

.detail-item-head .btn-cart {
  margin-bottom: 3rem;
}

.detail-item-head .btn-cart .button {
  width: 100%;
  max-width: 50rem;
  height: 6rem;
  font-size: 1.8rem;
}

.detail-item-head .btn-cart .button .las {
  margin-right: .8rem;
  font-size: 2.6rem;
}

.detail-item-head .btn-cart .button:hover {
  color: #000;
  background-color: #fff;
}

.detail-item-head .list-sub {
  display: flex;
  max-width: 52rem;
  margin: 0 -1rem;
}

.detail-item-head .list-sub > li {
  flex-basis: 50%;
  padding: 0 1rem;
}

.detail-item-head .list-sub .button {
  width: 100%;
  height: 5rem;
  font-size: 1.4rem;
}

.detail-item-head .list-sub .button .las {
  margin-right: 1rem;
  font-size: 1.8rem;
}

.detail-item-head .list-sub .button:hover {
  color: #fff;
  background-color: #000;
}

@media only screen and (max-width: 1200px) {
  .detail-item-head__images {
    flex-basis: 48.57143%;
    max-width: 48.57143%;
    width: 48.57143%;
  }
  .detail-item-head__information {
    flex-basis: 51.42857%;
    max-width: 51.42857%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .detail-item-head .item-detail-option ul {
    min-width: 100%;
  }
}

/* Item Decription */
.detail-item-decription {
  line-height: 1.71429;
  margin-bottom: 9rem;
}

.detail-item-decription h2 {
  line-height: 1.72727;
  margin-bottom: 1.36364em;
  padding-left: 0.86364em;
  border-left: 1px solid #000;
  font-size: 2.2rem;
}

.detail-item-decription h3 {
  line-height: 1.44444;
  margin-bottom: 1.22222em;
  font-size: 1.8rem;
}

.detail-item-decription p {
  margin-bottom: 1.71429em;
}

.detail-item-decription ul, .detail-item-decription ol, .detail-item-decription dl {
  margin-bottom: 1.71429em;
}

.detail-item-decription ul ul, .detail-item-decription ul ol, .detail-item-decription ul dl, .detail-item-decription ol ul, .detail-item-decription ol ol, .detail-item-decription ol dl, .detail-item-decription dl ul, .detail-item-decription dl ol, .detail-item-decription dl dl {
  margin-bottom: 0;
}

.detail-item-decription ul {
  padding-left: 1.42857em;
}

.detail-item-decription ul li {
  list-style-type: disc;
}

.detail-item-decription ol {
  padding-left: 1.42857em;
}

.detail-item-decription ol li {
  list-style-type: decimal;
}

.detail-item-decription dl > dd {
  margin-bottom: 0.71429em;
}

.detail-item-decription .box-spec {
  margin-bottom: 1.71429em;
  padding: 2rem;
  background-color: #EFEFEF;
}

.detail-item-decription .box-spec > dl {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -0.71429em;
}

.detail-item-decription .box-spec > dl > dt {
  flex-basis: 12rem;
}

.detail-item-decription .box-spec > dl > dd {
  flex-basis: calc(100% - 14rem);
  flex-grow: 1;
  padding-left: 2rem;
  min-height: 2.5rem;
}

/* Recommend & Recent */
.detail-item-recommend,
.detail-item-recent {
  margin-bottom: 9rem;
}

.detail-item-recommend .item-list,
.detail-item-recent .item-list {
  margin-bottom: -5.6rem;
}

.detail-item-recommend .bx-wrapper .bx-viewport,
.detail-item-recent .bx-wrapper .bx-viewport {
  box-shadow: none;
}

.detail-item-recommend .bx-wrapper .bx-controls-direction a,
.detail-item-recent .bx-wrapper .bx-controls-direction a {
  top: 0;
  margin-top: 8.28571%;
}

/* --------------------------------------------------
SEARCH
-------------------------------------------------- */
.page-search {
  margin: 10rem 0 16rem;
}

/* --------------------------------------------------
CART
-------------------------------------------------- */
.page-cart {
  margin: 10rem 0 16rem;
}

.page-cart .button {
  height: 5rem;
  padding: 0 2rem;
  font-size: 1.4rem;
}

.page-cart .button-small {
  height: 3rem;
  padding: 0 1rem;
}

.page-cart .basket {
  margin-bottom: 30px;
  border-top: 1px solid #EFEFEF;
}

.page-cart .basket th,
.page-cart .basket td {
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #EFEFEF;
  vertical-align: middle;
}

.page-cart .basket .basket-img {
  width: 60px;
}

.page-cart .basket .basket-img a {
  overflow: hidden;
  display: block;
  width: 60px;
  height: 60px;
}

.page-cart .basket .basket-img a img {
  width: 100%;
  height: auto;
}

.page-cart .basket .basket-option {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}

.page-cart .basket .basket-option select {
  margin: 0 20px 0 10px;
}

.page-cart .basket .basket-total {
  font-size: 1.8rem;
  background: #EFEFEF;
}

.page-cart .basket .basket-total .total-wrap .total-price {
  letter-spacing: 0.05em;
}

.page-cart .btn-wrap-order .button-a {
  font-size: 1.6rem;
}

.page-cart .section-wishlist {
  margin-top: 16rem;
}

.page-cart .section-wishlist .item-list form {
  flex-basis: 20%;
  max-width: 20%;
  margin-bottom: 5.6rem;
  padding: 0 1rem;
  text-align: center;
}

.page-cart .section-wishlist .item-list .item-list-img img {
  width: 100%;
  height: auto;
}

.page-cart .section-wishlist .item-list .item-list-detail .price b {
  font-weight: normal;
}

.page-cart .section-wishlist .item-list .item-list-detail .quantity,
.page-cart .section-wishlist .item-list .item-list-detail .stock {
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 2rem;
}

.page-cart .section-wishlist .item-list .item-list-detail .quantity dt,
.page-cart .section-wishlist .item-list .item-list-detail .stock dt {
  flex-basis: 4em;
}

.page-cart .section-wishlist .item-list .item-list-detail .quantity dd,
.page-cart .section-wishlist .item-list .item-list-detail .stock dd {
  flex-basis: calc(100% - 4em);
}

.page-cart .section-wishlist .item-list .item-list-button {
  margin-top: 3rem;
}

.page-cart .section-wishlist .item-list .item-list-button li {
  width: 100%;
  margin-bottom: 1.5rem;
}

.page-cart .section-wishlist .item-list .item-list-button li .button-a {
  width: 100%;
}

.page-cart .section-wishlist .item-list .item-list-button li .button-b {
  width: 100%;
  font-size: 1.2rem;
  height: 4rem;
}

/* --------------------------------------------------
NEWS
-------------------------------------------------- */
.page-news {
  margin: 10rem 0 16rem;
}

.page-news .news-list {
  max-width: initial;
  border-top: none;
}

.page-news .news-list table .lims {
  padding: 2rem 1rem;
}

.page-news .button {
  height: 5rem;
  padding: 0 2rem;
}

/* --------------------------------------------------
ORDER CONTRACT
-------------------------------------------------- */
#M_ordercontract {
  width: 60% !important;
  margin: 10rem auto 16rem;
}

/* --------------------------------------------------
SHOW ROOM
-------------------------------------------------- */
.page-showroom {
  margin-bottom: 16rem;
}

.page-showroom-lead {
  line-height: 2;
  margin-bottom: 11rem;
  text-align: center;
}

.showroom-list .showroom-info {
  margin-bottom: 8rem;
}

.showroom-list .showroom-info:nth-child(2n) .showroom-image {
  order: 1;
}

.showroom-list .showroom-info:nth-child(2n) .showroom-body {
  order: 0;
}

.showroom-info {
  display: flex;
  align-items: center;
  margin: 0 -2rem;
}

.showroom-info .showroom-image,
.showroom-info .showroom-body {
  flex-basis: 50%;
  padding: 0 2rem;
}

.showroom-info .showroom-image {
  text-align: center;
}

.showroom-info .showroom-image img {
  max-width: 100%;
  height: auto;
}

.showroom-info .showroom-name {
  line-height: 1.46154;
  margin-bottom: 1.4rem;
  font-size: 2.6rem;
}

.showroom-info .showroom-data {
  display: flex;
  flex-wrap: wrap;
}

.showroom-info .showroom-data > dt {
  align-self: flex-start;
  flex-basis: 8rem;
  max-width: 8rem;
  line-height: 1.46154;
  margin: 1rem 0;
  padding: .3rem .9rem .2rem;
  background-color: #EFEFEF;
  font-size: 1.3rem;
}

.showroom-info .showroom-data > dd {
  flex-basis: calc(100% - 14rem);
  flex-grow: 1;
  line-height: 1.71429;
  margin: 1rem 0;
  padding: 0 0 0 1rem;
  font-size: 1.4rem;
}

.showroom-info .showroom-text {
  margin-top: 2rem;
  font-size: 1.4rem;
}

.showroom-info .showroom-button {
  margin-top: 3rem;
}

.showroom-info .showroom-button .button {
  width: 28rem;
  height: 5rem;
}

/* --------------------------------------------------
BRAND STORY
-------------------------------------------------- */
.page-story {
  margin-bottom: 16rem;
}

.brand-story {
  display: flex;
  align-items: center;
}

.brand-story-image {
  flex-basis: 40%;
  max-width: 56rem;
  position: relative;
}

.brand-story-image img {
  max-width: 100%;
  height: auto;
}

.brand-story-body {
  flex-basis: 60%;
  max-width: 84rem;
}

.brand-story-headihg {
  line-height: 1.30435;
  font-size: 4.6rem;
  letter-spacing: 0.1em;
}

.brand-story-text {
  line-height: 2.28571;
  font-size: 1.4rem;
}

.brand-story-text p + p {
  margin-top: 1.42857em;
}

.brand-story-sub-text {
  line-height: 2.28571;
  font-size: 1.4rem;
}

.brand-story-01 {
  margin-top: 8rem;
  margin-bottom: 12.5rem;
}

.brand-story-01 .brand-story-image:after {
  content: '';
  position: absolute;
  bottom: -4.54545%;
  right: -14.28571%;
  z-index: -1;
  width: 100%;
  height: 86.36364%;
  background-color: #E0E0E0;
}

.brand-story-01 .brand-story-body {
  padding-top: 5.57143%;
  padding-left: 11.42857%;
}

.brand-story-01 .brand-story-headihg {
  margin-bottom: 6rem;
}

.brand-story-01 .brand-story-text {
  margin-bottom: 8rem;
  padding-left: 5.88235%;
}

.brand-story-01 .brand-story-sub-text {
  padding-left: 11.76471%;
  letter-spacing: 0.1em;
}

.brand-story-02 {
  margin-top: 14rem;
  margin-bottom: 8rem;
}

.brand-story-02 .brand-story-image {
  order: 1;
}

.brand-story-02 .brand-story-image:after {
  content: '';
  position: absolute;
  top: -5.26316%;
  left: -14.28571%;
  z-index: -1;
  width: 100%;
  height: 94.73684%;
  background-color: #E0E0E0;
}

.brand-story-02 .brand-story-body {
  order: 0;
  padding-right: 11.42857%;
}

.brand-story-02 .brand-story-headihg {
  margin-bottom: 6rem;
}

.brand-story-02 .brand-story-sub-text {
  margin-bottom: 7rem;
  padding-left: 5.88235%;
  letter-spacing: 0.04em;
}

.brand-story-02 .brand-story-text {
  padding-left: 11.76471%;
}

@media only screen and (max-width: 1200px) {
  .brand-story-headihg br {
    display: none;
  }
}

.brand-movie {
  max-width: 1240px;
  margin: 0 auto 14rem;
}

.brand-movie-heading {
  line-height: 1.30435;
  margin-bottom: 5.3rem;
  font-size: 4.6rem;
  letter-spacing: 0.06em;
}

.brand-movie-frame {
  position: relative;
  max-width: 79.03226%;
  margin: 0 auto;
}

.brand-movie-frame:before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.brand-movie-frame:after {
  content: '';
  position: absolute;
  top: -14.51906%;
  left: -4.08163%;
  z-index: -1;
  width: 81.63265%;
  height: 85.29946%;
  background-color: #E0E0E0;
}

.brand-movie-frame iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.brand-image {
  text-align: center;
}

.brand-image img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

/* --------------------------------------------------
guide
-------------------------------------------------- */
.page-guide {
  margin-bottom: 16rem;
}

.maintenance .M_guideNav {
  font-size: 0;
  white-space: normal;
  width: 100%;
  margin: 56px auto 64px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}

.maintenance .M_guideNav li {
  display: inline-block;
  padding: 2px 16px;
  border-right: dotted 1px #AAA;
}

.maintenance .M_guideNav li:last-child {
  border-right: none;
}

.maintenance .M_guideNav a {
  font-size: 15px;
  color: #333;
  text-decoration: none;
}

.maintenance h3 {
  line-height: 1.72727;
  margin-bottom: 1.36364em;
  padding-left: 0.86364em;
  border-left: 1px solid #000;
  font-size: 2.2rem;
}

.maintenance h4 {
  line-height: 1.44444;
  margin-bottom: 1.22222em;
  font-size: 1.8rem;
}

.maintenance ul {
  padding-left: 1.42857em;
}

.maintenance ul li {
  list-style-type: disc;
}

.maintenance ol {
  padding-left: 1.42857em;
}

.maintenance ol li {
  list-style-type: decimal;
}

.maintenance .guide-block {
  margin-bottom: 6rem;
}

.maintenance .guide-block-small {
  margin-bottom: 2.5rem;
}

.maintenance .guide-block-wrapper {
  margin-bottom: 8rem;
}

.maintenance .col-2 {
  display: flex;
  justify-content: space-between;
}

.maintenance .col-2 .cols {
  flex-basis: 50%;
}

.maintenance .col-2 .cols:nth-child(odd) {
  padding-right: 1%;
}

.maintenance .col-2 .cols:nth-child(even) {
  padding-left: 1%;
}

.maintenance .col-2 .tbl {
  width: 100%;
}

.maintenance .tbl {
  border-collapse: collapse;
  border: solid 1px #707070;
}

.maintenance .tbl th, .maintenance .tbl td {
  padding: 1rem 2rem;
  border: solid 1px #707070;
  font-size: 1.3rem;
  vertical-align: middle;
}

.maintenance .tbl th {
  background-color: #EFEFEF;
}

.maintenance .tbl thead td {
  text-align: center;
}

.maintenance .tbl img {
  max-width: 100%;
}

.maintenance .tbl p {
  margin-top: 1em;
}

/* --------------------------------------------------
GUIDE - MakeShop Built-in Page
-------------------------------------------------- */
#guide .inner-contents {
  width: 100%;
}

#guide .inner-contents pre {
  white-space: pre-wrap;
}

/* --------------------------------------------------
COMPANY - MakeShop Built-in Page
-------------------------------------------------- */
.page-about {
  width: 60% !important;
  margin: 10rem auto 16rem;
}

.page-about p {
  margin-bottom: 2em;
}
