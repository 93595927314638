@charset "utf-8";

/*
  ##### online.fisba.co.jp #####

  [Base]
  - Reset
  - Init
  [Layout]
  - Container
  - Header
  - Content
  - Footer
  [Module]

*/

// Import setting
@import "_variables.scss";
@import "_mixins.scss";

/* =========================================================
[Base]
========================================================= */
@import "_reset.scss";
@import "_init.scss";

/* =========================================================
[Layout]
========================================================= */
@import "_layouts.scss";

/* =========================================================
[Module]
========================================================= */
@import "_module.scss";

/* =========================================================
[Pages]
========================================================= */
@import "_pages.scss";
