@charset "utf-8";

/* --------------------------------------------------
  0. Reset
-------------------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
p,
blockquote,
th,
td,
form,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display:block;
  margin: 0;
  padding: 0;
}

body {
  line-height: 1;
}

div {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  line-height: 1.2;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

caption,
th,
td {
  text-align: left;
  vertical-align: top;
  font-weight: normal;
}

fieldset {
  border: none;
}

input,
textarea,
select,
label {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: middle;
}

label {
  cursor: pointer;
}

textarea {
  overflow: auto;
}

