@charset "utf-8";

/* //////////////////////////////////////////////////
[Layout]
////////////////////////////////////////////////// */
html {
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 62.5%;
}

body {
  color: $text-main-color;
  font-size: 1.0rem;
  line-height: 1.5;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  //word-wrap: break-word;
  //overflow-wrap: break-word;
  background-color: #ffffff;

  border-top: none;
}

a:link,
a:visited {
  color: $link-color;
  text-decoration: underline;
}

a:hover,
a:active {
  text-decoration: none;
}


/* --------------------------------------------------
  Container
-------------------------------------------------- */
.l-container {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

/* --------------------------------------------------
  Header
-------------------------------------------------- */
.l-header {
  background-color: #EFEFEF;
  text-align: left;
}

$height-header-upper: 4rem;

.header-upper {
  height: $height-header-upper;
  color: #fff;
  background-color: #000;

  .l-container {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

$height-header-lower: 14.6rem;

.header-lower {
  height: $height-header-lower;

  .l-container {
    position: relative;
    height: 100%;
    padding-top: 3.5rem;
  }
}

.header-tagline {
  line-height: (17 / 11);
  margin: 0 auto 0 0;
  font-size: 1.1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-utility-menu {
  display: flex;
  height: 100%;
  border-left: 1px solid #3B3B3B;

  > li {
    height: 100%;
    border-right: 1px solid #3B3B3B;
  }

  .button {
    height: 100%;
    line-height: 1;
    padding: 0 1.9rem;
    font-size: 1.3rem;
    white-space: nowrap;

    &:link,
    &:visited {
      color: #fff;
    }

    &:hover {
      opacity: .7;
    }

    .las {
      margin-right: .5rem;
      color: #777;
      font-size: 1.8rem;
    }
  }
}

.site-logo {
  margin: 0 auto;
  text-align: center;

   > a {
     text-decoration: none;

    &:link,
    &:visited {
      color: #222221;
    }
  }

  .svg-use {
    display: inline-block;
    width: 28.2rem;
    height: 9.3rem;
    color: #222221;
  }
}

.header-nav {
  position: static;

  li {
    display: block;
    margin: 0;
  }
}

.header-function {
  $this: &;
  position: absolute;
  top: 5rem;
  right: 2rem;

  &-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 27rem;
  }

  #{$this}-members {
    display: flex;
    align-items: center;
    width: 19rem;

    .las {
      margin-right: .5rem;
      font-size: 2.2rem;
    }

    .list-member {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding-top: 1.3em;

      li {
        margin: 0;

        a {
          position: relative;
          font-size: 1.2rem;
        }
      }

      .username {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden;
        width: 100%;
        font-size: 1.3rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #999;
      }
    }

    a {
      display: inline-block;
      line-height: (19 / 13);
      font-size: 1.3rem;
      text-decoration: none;
      @include transition-base;

      &:hover {
        opacity: .7;
      }
    }
  }

  &-cart {
    width: 7rem;

    #M_basketTemp1 #M_basketClosedBox a {
      display: flex;
      align-items: center;
      min-height: 32px;
      padding: 0;
      background-color: transparent;

      &:before {
        content: '\f07a';
        display: inline-block;
        position: relative;
        width: 1em;
        height: 1em;
        line-height: 1;
        margin-right: .5rem;
        font-size: 2.2rem;
        font-family: 'Line Awesome Free';
        font-weight: 900;
      }

      &:after {
        content: 'カート';
        display: inline-block;
        line-height: (19 / 13);
        font-size: 1.3rem;
      }

      &:link,
      &:visited {
        color: #000;
      }

      &:hover {

      }
    }

    #M_basketClosedBox .M_basketMarginBox {
      display: none;
    }
  }

  #{$this}-search {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1.8rem;
  }

  #MakeShopTopSearchInput {
    width: calc(100% - 3rem);
    height: 3rem;
    border: none;
  }

  #MakeShopTopSearchButton {
    position: relative;
    width: 3rem;
    height: 3rem;
    margin: 0;
    border-radius: 0;
    color: #fff;
    background-image: none;
    text-decoration: none;

    &:before {
      content: '\f002';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 1em;
      height: 1em;
      line-height: 1em;
      margin: auto;
      font-size: 1.8rem;
      font-family: 'Line Awesome Free';
      font-weight: 900;
      text-indent: 0;
    }
  }
}


/* --------------------------------------------------
  Humburger Button
-------------------------------------------------- */

/* --------------------------------------------------
  Global Nav
-------------------------------------------------- */
$height-header-gnav: 5.4rem;

.header-gnav {
  display: flex;
  justify-content: center;
  position: relative;
  height: $height-header-gnav;
  line-height: (20 / 14);
  font-size: 1.4rem;

  > li {
    height: 100%;

    &:hover > .mega-menu {
      opacity: 1;
      visibility: visible;
      @include transition-base($duration: .3s);
    }

    &:hover > a:after {
      transform: scaleX(1);
      opacity: 1;
    }

    > a {
      display: block;
      position: relative;
      height: 100%;
      padding: 1.8rem 2.5rem 0;

      &:after {
        content: '';
        position: absolute;
        //top: 3.5rem;
        bottom: 0;
        right: 0;
        left: 0;
        width: calc(100% - 3.8rem);
        height: 1px;
        margin: 0 auto;
        background-color: #000;
        transform: scaleX(.3);
        opacity: 0;
        @include transition-base;
      }

      &:hover:after {
        //transform: scaleX(1);
        //opacity: 1;
      }
    }
  }

  a {
    position: relative;
    text-decoration: none;
    @include transition-base;
  }
}

.mega-menu {
  position: absolute;
  top: $height-header-gnav;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff;

  opacity: 0;
  visibility: hidden;
  @include transition-base($duration: .15s);
  //transition-delay: .1s;

  &-row {
    display: flex;
    justify-content: center;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }

  &-image,
  &-col {
    flex-basis: percentage(480 / 1440);
    max-width: percentage(480 / 1440);
  }

  &-col {
    padding: 6rem 2rem 0;
  }


  &-image {
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &-title {
    line-height: (28 / 16);
    padding-left: (19 / 16)+em;
    border-left: 1px solid #000;
    font-size: 1.6rem;
  }
}

.sub-menu {
  display: flex;
  flex-wrap: wrap;
  line-height: (22 / 14);
  margin: -.4rem -1rem 0;
  font-size: 1.4rem;

  > li {
    flex-basis: 50%;
    max-width: 50%;
    margin-top: 2.4rem;
    padding: 0 1rem;
  }

  a {
    display: inline-block;
    padding-left: (15 / 14)+em;
    @include transition-base;

    &:before {
      content: '\f138';
      position: absolute;
      top: 0;
      left: -1px;
      color: #707070;
      font-family: 'Line Awesome Free';
      font-weight: 900;
    }

    &:hover {
      opacity: .7;
    }
  }

  &.menu-bottom{
    margin-top: 4.2rem;
  }
}

//959px以下
@include mediaquery-tablet() {
  .mega-menu{
    &-image{
      position: relative;
      overflow: hidden;

      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50%);
        max-width: none;
      }
    }

    &-col {
      padding: 4rem 2rem 1rem;
    }
  }
}


/* --------------------------------------------------
  Contents
-------------------------------------------------- */
.l-contents {
  text-align: left;
  font-size: 1.4rem;
}



/* --------------------------------------------------
  Footer
-------------------------------------------------- */
.l-footer {
  padding: 0 0 6.2rem;
  color: #fff;
  background-color: #000;
  text-align: left;
}

.footer-utility {
  background-color: #EFEFEF;

  &-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    line-height: (24 / 14);
    font-size: 1.4rem;

    > li {
      display: flex;
      align-items: center;
      height: 4rem;
      padding: 0 6rem;
      border-right: 1px solid #B5B5B5;

      &:first-child {
        border-left: 1px solid #B5B5B5;
      }
    }

    a {
      text-decoration: none;
      @include transition-base;

      &:hover {
        opacity: .7;
      }
    }

    .las {
      position: relative;
      top: .2rem;
      margin-right: .5rem;
      color: #777777;
      font-size: 1.8rem;
    }
  }
}

.footer-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24.7em;
  background: url(../img/common/bg_footer_info.jpg) no-repeat center center;
  background-size: cover;

  &-box {
    display: flex;
    align-items: center;
  }

  &-logo {
    width: 28rem;
  }

  &-body {
    line-height: (20 / 14);
    padding-left: 4.2rem;
    font-size: 1.4rem;
  }

  &-button {
    margin-top: 1.7rem;

    .button {
      width: 22rem;
      height: 5rem;
      margin: 0;
    }
  }
}

.footer-nav {
  $this: &;
  display: flex;
  margin: 0 -2rem;
  padding: 8rem 0 7rem;
  color: #fff;
  background-color: transparent;

  &-col {
    width: percentage(440 / 1440);
    min-width: 37rem;
    padding: 0 2rem;
  }

  &-sns {
    position: absolute;
    top: 8rem;
    right: 2rem;
  }

  &-heading {
    line-height: (24 / 16);
    margin: 0 0 2.4rem;
    font-size: 1.6rem;
  }

  &-menu-row {
    display: flex;
    margin: 0 -1rem;

    #{$this}-menu {
      width: 50%;
      padding: 0 1rem;
    }
  }

  &-menu {
    line-height: (18 / 12);
    font-size: 1.2rem;

    > li {
      margin-bottom: 1rem;
    }

    .las {
      position: relative;
      top: .1rem;
      font-size: 1.6rem;
    }

    a {
      text-decoration: none;
      @include transition-base;

      &:link,
      &:visited {
        color: #fff;
      }

      &:hover {
        opacity: .7;
      }
    }
  }
}

.footer-sns-list {
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 0 -1.5rem;
  font-size: 2.8rem;

  .la-instagram {
    font-size: 3rem;
  }

  > li {
    padding: 0 1.5rem;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    @include transition-base;

    &:link,
    &:visited {
      color: #fff;
    }

    &:hover {
      opacity: .7;
    }
  }
}

.footer-copyright {
  font-size: 1.2rem;
  text-align: center;
  @include ls-center(50);
}
