@charset "utf-8";

// -------------------------------------------------
// Gloval variables setting
// -------------------------------------------------

// Colors ----------------------
$main-color:       #17589e !default;
// $sub-color:        #f28b1d !default;

$text-main-color:  #333333 !default;
$text-sub-color:   #666666 !default;

$link-color:       #000 !default;

$link-btn-color:   #333 !default;
$link-btn-color-active:   #17589e !default;

// Font size
// -------------------------------------------------
$font-size-base:   62.5% !default;


// Font family
// -------------------------------------------------
$font-family-base: 'Poppins', 'Noto Sans JP', sans-serif !default;
$font-family-en:   'EB Garamond', sans-serif !default;


// Line height
// -------------------------------------------------
$base-line-height:  1.5 !default;
$typo-line-height:  1.2 !default;


// media query
// -------------------------------------------------
$width-pc:          960px;
$width-tablet:      768px;
$width-sp:          640px;


// Other
// -------------------------------------------------
$opacity-transparent: 0.7 !default;

